import React from "react";
import { ReactTyped } from "react-typed";
import { Col, Container, Row } from "react-bootstrap";
import logo from "./logo.jpg";
import addy from "./addy.png";
import min from "./min.png";
import iconAarp from "./icon-aarp.svg";
import iconAol from "./icon-aol.svg";
import iconApple from "./icon-apple.svg";
import iconAtt from "./icon-att.svg";
import iconEw from "./icon-ew.svg";
import iconFord from "./icon-ford.svg";
import iconFortune from "./icon-fortune.svg";
import iconMagnolia from "./icon-magnolia.svg";
import iconMulesoft from "./icon-mulesoft.svg";
import iconNetjets from "./icon-netjets.svg";
import iconOsu from "./icon-osu.svg";
import iconPeoplemag from "./icon-peoplemag.svg";
import iconPopsci from "./icon-popsci.svg";
import iconSalesforce from "./icon-salesforce.svg";
import iconSi from "./icon-si.svg";
import iconTime from "./icon-time.svg";
import iconYahoo from "./icon-yahoo.svg";

const icons = [
  {
    id: 1,
    title: "Apple",
    alt: "Apple Logo",
    img: iconApple
  },
  {
    id: 2,
    title: "Yahoo",
    alt: "Yahoo Logo",
    img: iconYahoo
  },
  {
    id: 3,
    title: "AOL",
    alt: "AOL Logo",
    img: iconAol
  },
  {
    id: 4,
    title: "Ford",
    alt: "Ford Logo",
    img: iconFord
  },
  {
    id: 5,
    title: "AT&T",
    alt: "AT&T Logo",
    img: iconAtt
  },
  {
    id: 6,
    title: "TIME",
    alt: "TIME Logo",
    img: iconTime
  },
  {
    id: 7,
    title: "AARP",
    alt: "AARP Logo",
    img: iconAarp
  },
  {
    id: 8,
    title: "People Magazine",
    alt: "People Magazine Logo",
    img: iconPeoplemag
  },
  {
    id: 9,
    title: "Popular Science",
    alt: "Popular Science Logo",
    img: iconPopsci
  },
  {
    id: 10,
    title: "Salesforce",
    alt: "Salesforce Logo",
    img: iconSalesforce
  },
  {
    id: 11,
    title: "Sports Illustrated",
    alt: "Sports Illustrated Logo",
    img: iconSi
  },
  {
    id: 12,
    title: "Magnolia",
    alt: "Magnolia Logo",
    img: iconMagnolia
  },
  {
    id: 13,
    title: "Fortune",
    alt: "Fortune Logo",
    img: iconFortune
  },
  {
    id: 14,
    title: "Ohio State University",
    alt: "Ohio State University Logo",
    img: iconOsu
  },
  {
    id: 15,
    title: "Entertainment Weekly",
    alt: "Entertainment Weekly Logo",
    img: iconEw
  },
  {
    id: 16,
    title: "NetJets",
    alt: "NetJets Logo",
    img: iconNetjets
  },
  {
    id: 17,
    title: "MuleSoft",
    alt: "MuleSoft Logo",
    img: iconMulesoft
  }
]

const coreSkills = [
  {
    id: 1,
    title: "UI/UX",
    desc: "15 years of experience in a variety of industries, collaborating effectively with major brands like Apple and Salesfoce, to build elegant, accessible, and highly effective multi platform user experiences."
  },
  {
    id: 2,
    title: "Development",
    desc: "24 years as a web developer, focused on responsive, cross-platform front-end architecture and content management systems."
  },
  {
    id: 3,
    title: "Product Design",
    desc: "Thrive in fast-paced product environments, collaborating closely with stakeholders throughout the entire project lifecycle to deliver exceptional digital solutions."
  },
  {
    id: 4,
    title: "CGI/3D/Motion",
    desc: "Motivated digital creative, exploring other disclpines outside of the web, including 3D character modeling, motion graphics, and game development."
  }
]

function App() {
  return (
    <div className="App">
      <Container className="px-4" fluid>
        <Row className="gx-5 h-100 justify-content-md-center">
          <Col className="px-0 d-flex flex-column" md={12} lg={8} xl={8}>
            <h1 className="px-4 px-sm-4 px-md-4 px-lg-5 my-5">
              <img className="logo" src={logo} alt="Todd Walden" /> <b>Todd Walden</b> <ReactTyped strings={["is an experienced and versatile web professional with focused expertise in user experience and product design, front-end architecture, web compliance, and content management systems."]} typeSpeed={25} />
            </h1>
            <div className="featured-skills-wrapper">
              {coreSkills.map(({ id, title, desc }) => (
                <div key={id}>
                  <div>
                    <h2>{title}</h2>
                    <p>{desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col className="sidebar px-4 px-sm-4 px-md-4 px-lg-5 my-5" md={12} lg={4} xl={4}>
            <div className="contact-links">
              <a
                className="contact-link"
                href="https://www.linkedin.com/in/toddwalden/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="d-inline">Get In Touch</span>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Todd Walden - LinkedIn</title>
                  <path
                    d="M20 0C8.955 0 0 8.955 0 20s8.955 20 20 20 20-8.955 20-20S31.045 0 20 0zm-3.333 26.667h-3.334v-10h3.334v10zm-1.667-12c-.92 0-1.667-.746-1.667-1.667 0-.92.746-1.667 1.667-1.667s1.667.747 1.667 1.667c0 .921-.747 1.667-1.667 1.667zm13.667 12h-3.33v-4.76c0-3.13-3.337-2.866-3.337 0v4.76h-3.333v-9.983H22v1.818c1.453-2.688 6.667-2.888 6.667 2.576v5.589z"
                    fill="#f4f4f4"
                    fill-rule="nonzero"
                  />
                </svg>
              </a>
              <a
                className="contact-link"
                href="https://toddwalden.dribbble.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Todd Walden - Dribbble</title>
                  <path d="M0 20C0 16.3733 0.89336 13.0267 2.68 9.96C4.46668 6.89332 6.89336 4.46668 9.96 2.68C13.0267 0.89332 16.3734 0 20 0C23.6267 0 26.9734 0.89332 30.04 2.68C33.1067 4.46668 35.5334 6.89332 37.32 9.96C39.1067 13.0267 40 16.3733 40 20C40 23.6267 39.1067 26.9733 37.32 30.04C35.5334 33.1067 33.1067 35.5333 30.04 37.32C26.9734 39.1067 23.6267 40 20 40C16.3734 40 13.0267 39.1067 9.96 37.32C6.89336 35.5333 4.46668 33.1067 2.68 30.04C0.89336 26.9733 0 23.6267 0 20ZM3.32 20C3.32 24.16 4.72 27.8267 7.52 31C8.8 28.4933 10.8267 26.1067 13.6 23.84C16.3734 21.5733 19.08 20.1467 21.72 19.56C21.32 18.6267 20.9334 17.7867 20.56 17.04C15.9734 18.5067 11.0134 19.24 5.68 19.24C4.64 19.24 3.86668 19.2267 3.36 19.2C3.36 19.3067 3.35336 19.44 3.34 19.6C3.32668 19.76 3.32 19.8933 3.32 20ZM3.84 15.88C4.42668 15.9333 5.29336 15.96 6.44 15.96C10.8934 15.96 15.12 15.36 19.12 14.16C17.0934 10.56 14.8667 7.56 12.44 5.16C10.3334 6.22668 8.52668 7.70668 7.02 9.6C5.51336 11.4933 4.45336 13.5867 3.84 15.88ZM9.8 33.16C12.8134 35.5067 16.2134 36.68 20 36.68C21.9734 36.68 23.9334 36.3067 25.88 35.56C25.3467 31 24.3067 26.5867 22.76 22.32C20.3067 22.8533 17.8334 24.2 15.34 26.36C12.8467 28.52 11 30.7867 9.8 33.16ZM15.92 3.88C18.2667 6.30668 20.44 9.33332 22.44 12.96C26.0667 11.44 28.8 9.50668 30.64 7.16C27.5467 4.6 24 3.32 20 3.32C18.64 3.32 17.28 3.50668 15.92 3.88ZM23.88 15.8C24.28 16.6533 24.7334 17.7333 25.24 19.04C27.2134 18.8533 29.36 18.76 31.68 18.76C33.3334 18.76 34.9734 18.8 36.6 18.88C36.3867 15.2533 35.08 12.0267 32.68 9.2C30.9467 11.7867 28.0134 13.9867 23.88 15.8ZM26.24 21.84C27.6 25.7867 28.52 29.84 29 34C31.1067 32.64 32.8267 30.8933 34.16 28.76C35.4934 26.6267 36.2934 24.32 36.56 21.84C34.6134 21.7067 32.84 21.64 31.24 21.64C29.7734 21.64 28.1067 21.7067 26.24 21.84Z" fill="#F4F4F4"/>
                </svg>
              </a>
              <a
                className="contact-link d-none"
                href="https://www.linkedin.com/in/toddwalden/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="33"
                  height="40"
                  viewBox="0 0 33 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Todd Walden - Resume</title>
                  <g fill="#050609" fill-rule="nonzero">
                    <path d="M3.3 36.667V3.333h26.4v19.239c0 6.845-9.9 4.095-9.9 4.095s2.505 10-4.353 10H3.3zM33 23.977V0H0v40h16.812C22.03 40 33 27.962 33 23.977z" />
                    <path d="M15.702 20.685c-.077 1.07-.473 1.914-1.186 2.529s-1.653.923-2.82.923c-1.276 0-2.28-.43-3.01-1.289-.732-.859-1.098-2.038-1.098-3.537v-.609c0-.957.169-1.8.506-2.53.337-.728.819-1.288 1.446-1.677.626-.39 1.354-.585 2.184-.585 1.148 0 2.073.308 2.775.923s1.107 1.479 1.217 2.59h-2.051c-.05-.642-.229-1.108-.537-1.397-.307-.29-.775-.434-1.404-.434-.684 0-1.196.245-1.535.735-.34.49-.514 1.25-.523 2.28v.751c0 1.076.163 1.862.489 2.359.326.497.84.745 1.541.745.634 0 1.107-.145 1.419-.434.312-.29.49-.737.536-1.343h2.051zm5.525.847l2.255-7.485h2.284L22.3 24h-2.14l-3.452-9.953h2.276l2.243 7.485z" />
                  </g>
                </svg>
              </a>
            </div>
            <p>
              Holistic understanding of user experience design and how best to apply it in several industries, including marketing, retail/e-commerce, education/LMS, publishing, artificial intelligence (AI), web3/DLT, enterprise, gaming, API ecosystems, and application platforms.
            </p>
            <p>
              Comfortable working closely in fast-paced creative and technical environments with business units, product owners, brand directors, editors, marketers, technical architects, and other developers to deliver exceptional digital products and services.
            </p>
            <h3>Focus areas</h3>
            <ul className="focus-areas-listing">
              <li>UI/UX/Product Design</li>
              <li>Interaction Design</li>
              <li>Front-end Development</li>
              <li>Cross-Platform Responsive Design</li>
              <li>Branding</li>
              <li>Usability/Accessibility</li>
              <li>CGI/3D Modeling</li>
            </ul>
            <h3>Organizations & Brands</h3>
            <ul className="companies-brands-listing">
              {icons.map(({ id, title, alt, img }) => (
                <li>
                  <img title={title} alt={alt} key={id} src={img} />
                </li>
              ))}
            </ul>
            <h3>Awards</h3>
            <ul className="awards-listing">
              <li>
                <img alt="Addy Logo" src={addy} />
                <span>Gold Addy Award Winner - B2B</span>
              </li>
              <li>
                <img alt="Min Logo" src={min} />
                <span>Min's Best of the Web - Digital Team</span>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
